import { GetLancamento, LancamentoMensalModel } from "@/models/LancamentoMensalModel";
import ApiService from "./ApiService";

export const lancamentoIndex = async (codConcessionaria, payload ) : Promise<any> => {
   const { data } = await ApiService.post(`/lancamentoIndex/${codConcessionaria}`, payload);
   return data;
};

export const lancamentoView = async (codConcessionaria, codLancamento ): Promise<GetLancamento> => {
   const { data } = await ApiService.get(`/lancamentoView/${codConcessionaria}/${codLancamento}`);
   return data;
};

export const deleteLancamentos= async (codConcessionaria,idLancamento) => {
   const { data } = await ApiService.post(`/deletelancamento/${codConcessionaria}/${idLancamento}`, {});
   return data;
};

export const getDateLancamento = async (codConcessionaria,payload) => {
   const { data } = await ApiService.post(`/addLancamento/${codConcessionaria}`,payload);
   return data;
};

export const newLancamento = async (codConcessionaria,payload) => {
   const { data } = await ApiService.post(`/newLancamento/${codConcessionaria}`, payload);
   return data;
};

export default {lancamentoIndex,lancamentoView,deleteLancamentos,getDateLancamento,newLancamento};