
  import Datatable from "@/components/datatable/Datatable.vue";
  import { defineComponent, reactive, ref, onMounted } from "vue";
  import moment from 'moment';
  import { lancamentoIndex } from "@/services/LancamentoMensal";
  import { ContentLoader } from "vue-content-loader";
  import useEmitter from "@/composables/Emmiter";
  import ExcluirLancamento from "./components/ExcluirLancamento.vue";
  import VisualizarLancamento from "./components/VisualizarLancamento.vue";
  import { converterDataNumber } from "./composables/dataNumberConverter"
  import  ModalAddLancamento  from "./components/ModalAddLancamento.vue"
  import { useStore } from "vuex";

  interface DataConcessionaria {
    nomeFantasia : string
  }

  interface DataLancamento {
    codLancamento: number;
    codConcessionaria: number;
    mes: string;
    ano: string;
    dia: number;
    faturamento: string;
    dataCadastro: string;
    idCadastro: number;
  }

  interface DataVendas{
    concessionaria : DataConcessionaria
    lancamentos : DataLancamento
  }

  interface Info {
    current_page: number;
    data: DataLancamento[]; // o tipo do array aqui depende do tipo de objeto que está dentro do array
    from: number;
    last_page: number;
    last_page_url: string;   
    next_page_url: string;
    per_page: number;
    to: number;
    total: number;
}

  export default defineComponent({
    name: "concessionariaadd",
    components: {
      ExcluirLancamento,
      VisualizarLancamento,
      Datatable,
      ContentLoader,
      ModalAddLancamento,      
    },
    setup() {
      const animate = ref(true);
      const emitter = useEmitter();
      const store = useStore()
      const postData: any = ref({}); 
      const datalancamentoSelectView = ref() 
      const datalancamentoSelectDelete = ref() 
      const selectConcessionariaSelectedLocalStorage = ref(localStorage.getItem("filtroConcessionaria"));
      const codconcessionaria:any = ref({});
      const dadosLancamentoMensal = ref({});
      const showPaginate = ref(false)
      const allReleases = ref()
      const codNivelUsuario = store.getters.getCodNivel
      const codNivelAutorizado = [-1,2]
      const filter = reactive({
        ano: 0,
        mes: 0
      })
      const data : {info : Info}= reactive({
         info: {
            current_page: 1,
            data: [],
            from: 1,
            last_page: 7,
            last_page_url: "",
            next_page_url: "",
            per_page: 50,
            to: 0,
            total: 0
         },
      });

      onMounted(() => {        

        const container = window.document.getElementById('kt_content_container')
        container?.classList.remove('container-fluid')
        container?.classList.add('container-xxl')

        dadosLancamento(selectConcessionariaSelectedLocalStorage.value,filter);
      });

      emitter.on("filtrar-vendas-mensal", async data => {
        animate.value = true;
        postData.value = {
          codConcessionariaSelected: data.filtrosServicos.codConcessionariaSelected, 
        };
        animate.value = false;
      });

      async function dadosLancamento(codconcessionaria, filtro){
        animate.value = true
        showPaginate.value = false
        const response = await lancamentoIndex(codconcessionaria,filtro);
        allReleases.value = response
        paginate(1)
        showPaginate.value = true
        animate.value = false
      }
      function chamarConcessionariaEspecifica(lancamentos){
        dadosLancamentoMensal.value = lancamentos
      }
      
      function formatarData(databruta) {
        if (databruta) {                                                                                  
          return moment(String(databruta)).format('DD/MM/YYYY HH:mm')
        }
      }

      emitter.on('filter-lancamento-mensal',async (filterData) => {
        const {codConcessionaria} = filterData        
        dadosLancamento(codConcessionaria,filterData.datePicker);
      })    

     const lancamentoSelectView = (lancamentoSelet) => {
      datalancamentoSelectView.value = lancamentoSelet
     }

     const lancamentoSelectDelete = (lancamentoSelet) => {
      datalancamentoSelectDelete.value = lancamentoSelet
     }

     function paginate(page){

      let lancamentos = []

      const ordBy = allReleases.value.lancamento.sort((a,b) => {
        const dataA : any = new Date(a.dataCadastro);
        const dataB : any = new Date(b.dataCadastro);
        return dataB - dataA;
      })

      lancamentos = ordBy
      
      data.info.total = lancamentos.length;
      data.info.last_page = Math.ceil((lancamentos.length / data.info.per_page)) ?? 0;

      data.info.current_page = page;
      const start = data.info.per_page * (page - 1);
      const end =  data.info.per_page * (page);
      if(data.info.last_page == page){
        data.info.to = lancamentos.length;
      } else if(end > lancamentos.length){
        data.info.to = lancamentos.length;
      } else {
        data.info.to = end;
      }
    
      data.info.data = lancamentos.slice(start, end);  
      window.scroll({
              top: 0,
              behavior: 'smooth'
      })
     }

     emitter.on('update-release-list',() => {
      const selectConcessionariaSelectedLocalStorage = localStorage.getItem("filtroConcessionaria");

      dadosLancamento(selectConcessionariaSelectedLocalStorage,filter)
     })

      return {
        dadosLancamento,
        formatarData,
        data,
        animate,
        codconcessionaria,
        postData,
        dadosLancamentoMensal,
        chamarConcessionariaEspecifica,
        lancamentoSelectView,
        datalancamentoSelectView,
        paginate,
        allReleases,
        showPaginate,
        converterDataNumber,
        codNivelUsuario,
        codNivelAutorizado,
        lancamentoSelectDelete,
        datalancamentoSelectDelete
      };
    },
  });
  